<template>
  <div>
    <div class="push-date">
      <v-row v-if="showLastPush">
        <v-col cols="4">
          {{ $t('components.post-push-settings.last-push-at') }}
        </v-col>
        <v-col cols="8">
          {{ lastPushText }}
        </v-col>
      </v-row>
    </div>

    <div v-show="canPushNow" class="push-notification">
      <v-row>
        <v-col cols="4">
          Manual Push
        </v-col>
        <v-col cols="8">
          <v-btn
              color="primary"
              elevation="0"
              class="icon-left push-abort"
              @click="push()"
          >
            <v-icon>mdi-bell</v-icon>
            {{ $t('components.post-push-settings.do-push') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-show="canConfigurePush">
      <v-row>
        <v-col cols="4">
          {{ $t('components.post-push-settings.automatic-push') }}
        </v-col>
        <v-col cols="8">
          <v-radio-group v-model="pushMode">
            <v-radio
                key="manual"
                :label="$t('components.post-push-settings.no-automatic-push')"
                value="off"
            />
            <v-radio
                v-if="canPushOnPublish"
                key="publish"
                :label="$t('components.post-push-settings.on-publish-push')"
                value="onpublish"
            />
            <v-radio
                v-if="canAutoPush"
                key="publish"
                :label="$t('components.post-push-settings.automatic-push')"
                value="auto"
            />
            <v-radio
                key="scheduled"
                :label="$t('components.post-push-settings.schedule-push-at')"
                value="scheduled"
            />
            <app-date-time-picker
                v-if="pushMode === pushModeScheduled"
                v-model="scheduledPushDate"
                date-format="dd.MM.yyyy"
                time-format="HH:mm"
                :id="'publishedDate'"
                :clearable="true"
                label=""
            />
            <div v-if="!pushDateValid">{{ $t('components.post-push-settings.push-date-must-be-after') }}
              {{ formatDateTime(pushNotBefore) }}.
            </div>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

import AppDateTimePicker from "@/components/vuetify/AppDateTimePicker.vue";
import moment from 'moment'

export default {
  name: "NewsPush",
  components: {AppDateTimePicker},
  props: {
    dataCy: {
      default: ''
    },
    value: {},
    showLastPush: {},
    lastPush: {},
    canPushNow: {},
    canConfigurePush: {},
    canPushOnPublish: {},
    canAutoPush: false,
    pushNotBefore: {},
  },
  emits: [
    'push-now',
  ],
  data() {
    return {
      pushMode: this.value.mode,
      scheduledPushDate: this.value.date,
    }
  },
  computed: {
    pushModeOff() {
      return 'off'
    },
    pushModeOnPublish() {
      return 'pushat'
    },
    pushModeScheduled() {
      return 'scheduled'
    },
    lastPushText() {
      if (this.lastPush) {
        return this.formatDateTime(this.lastPush)
      }
      return '-'
    },
    pushDateValid() {
      return this.pushMode !== this.pushModeScheduled
          || !this.scheduledPushDate
          || (this.pushNotBefore && this.scheduledPushDate > this.pushNotBefore)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(push) {
        this.pushMode = push.mode;
        this.scheduledPushDate = push.date;
      },
    },
    pushMode(val) {
      this.updated()
    },
    scheduledPushDate(val) {
      this.updated()
    },
  },
  methods: {
    push() {
      this.$emit('push-now', null)
    },
    updated() {
      this.$emit('valid', this.pushDateValid)
      this.$emit('input', {mode: this.pushMode, date: this.scheduledPushDate})
    },
  }
}
</script>


<style scoped lang="scss">

</style>
