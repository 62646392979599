<template>
    <div>
        <div v-if="previewImage" class="preview-page-header">
            <img :src="previewImage" />
        </div>
        <div
            class="preview-element"
            v-for="(block, index) in blocks"
            :key="index"
        >
            <div v-if="block.name === 'Text'" v-html="block.content" class="text-block-preview"></div>

            <div v-if="block.name === 'Bild'" class="image-block-preview">
                <content-image-to-base64 v-if="!block.new_content.includes('base64')" :value="block"></content-image-to-base64>
                <img class="test" v-else :src="block.new_content">
            </div>

            <div v-if="block.name === 'Video' && block.content !== '' && !block.isUploading" class="video-block-preview">
                <video :src="tempVideoSrc(block.video)" controls></video>
            </div>

            <div v-if="block.name === 'Video' && block.content === '' && !block.isUploading" class="video-block-preview">
                <video :src="block.video" controls></video>
            </div>

            <div v-if="block.name === 'Youtube'" class="youtube-block-preview">
                <div style="position:relative;overflow:hidden;padding-bottom:56.25%;margin:16px 0;">
                    <iframe
                        :src="getYoutubeSrc(block.content)"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
                        style="position:absolute;width:100%;height:100%;"
                    ></iframe>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ContentImageToBase64 from '@/components/ContentImageToBase64'

export default {
    name: "NewsPreview",
    components: {
        ContentImageToBase64
    },
    props: {
        previewImage: {},
        blocks: {}
    },
    methods: {
        tempVideoSrc(video) {
            return URL.createObjectURL(video)
        },
        getYoutubeSrc(youtubeID) {
            return 'https://www.youtube.com/embed/' + youtubeID + '?rel=0'
        }
    }
}
</script>

<style scoped lang="scss">
.preview-page-header {
    margin-bottom: 1em;
}

.preview-element {

    h1 {
        font-size: 1.75rem;
        color: #000000;
        margin-top: 0;
        margin-bottom: 0.25em;
        font-weight: 600;
    }

    h2 {
        font-size: 1.5rem;
        color: #000000;
        margin-top: 0;
        margin-bottom: 0.25em;
        font-weight: 600;
    }

    p {
        margin-bottom: 0.5em;
    }
}
</style>
