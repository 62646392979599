<template>
  <md-list-item>
    <v-icon>{{ item.icon }}</v-icon>
    <span class="module-title">{{ item.title }}</span>
    <md-list-expand>
      <md-list>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/expenses' }">
            <span>{{ $t('navigation.expenses') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/expense-reports' }">
            <span>{{ $t('navigation.expense-reports') }}</span>
          </router-link>
        </md-list-item>
      </md-list>
    </md-list-expand>
  </md-list-item>
</template>


<script>

export default {
  name: 'ExpenseListItem',
  data() {
    return {

    }
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: {}
    },
    user: {
      type: Object,
      required: false,
      default: {}
    },
    modules: {
      type: Array,
      required: false,
      default: []
    }
  }

}
</script>