<template>
  <div>
    <BasicEditContainer
        :title="t('edit.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        :save-action="saveItem"
        @saved="onSaved"
        @cancel="close"
        :allow-delete="false">
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
                v-model="data.salutation"
            ></salutation-select>
            <v-text-field
                filled
                :label="t('details.firstname') + '*'"
                counter="120"
                v-model="data.firstname"
                :rules="[
								() => !!data.firstname || $t('validation.required.firstname'),
								() => (data.firstname && data.firstname.length <= 120) || $t('validation.length.default')
							]"
                :readonly="isManagedByImport"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.lastname')"
                counter="120"
                v-model="data.name"
                :rules="[
								() => !!data.name || $t('validation.required.lastname'),
								() => (data.name && data.name.length <= 120) || $t('validation.length.default')
							]"
                :readonly="isManagedByImport"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.email')"
                v-model="data.email"
                :rules="emailRules"
                :readonly="isManagedByImport"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.phone')"
                v-model="data.phone_number"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('details.phone-mobile')"
                v-model="data.mobile_phone_number"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('details.phone-emergency')"
                v-model="data.emergency_phone_number"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('details.workplace')"
                v-model="data.workplace"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('details.profession')"
                v-model="data.profession"
                :readonly="isManagedByImport"
            />

            <v-text-field
                filled
                :label="t('details.bank')"
                v-model="data.bank"
            />

            <v-text-field
                filled
                :label="t('details.iban')"
                v-model="data.iban"
            />

            <app-filter-date-picker
                :filterLabel="t('details.birth_date')"
                v-model="data.birth_date"
                id="dateStart"
                :clearable="true"
            ></app-filter-date-picker>

            <v-textarea
                filled
                :label="t('details.notes')"
                v-model="data.notes"
            />

            <span>
              {{ t('details.cooperation') }}
            </span>
            <v-row class="mt-1">
              <v-col>
                <app-filter-date-picker
                    :filterLabel="t('details.begin_date')"
                    v-model="data.begin_date"
                    id="dateStart"
                    :clearable="true"
                ></app-filter-date-picker>
              </v-col>
              <v-col>
                <app-filter-date-picker
                    :filterLabel="t('details.end_date')"
                    v-model="data.end_date"
                    id="dateStart"
                    :clearable="true"
                ></app-filter-date-picker>
              </v-col>
            </v-row>

            <v-checkbox
                v-model="data.active"
                :label="t('details.active')"/>
            

            <v-checkbox
                :label="t('details.phonebook-visible')"
                v-model="data.phonebook_hidden"
                v-if="moduleEnabled('phonebook')"
                :true-value="0"
                :false-value="1"
            />
          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('avatar.group-title') }}</v-card-title>
          <v-card-text>
            <PictureUpload :deletion=true v-model="data.picture" @picture="refresh" aspectMode="quadratic"/>
          </v-card-text>
        </v-card>
        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('address.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
                filled
                :label="t('address.address_line_1')"
                v-model="data.address.address_line_1"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('address.address_line_2')"
                v-model="data.address.address_line_2"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('address.zip_code')"
                v-model="data.address.zip_code"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('address.place')"
                v-model="data.address.place"
                :readonly="isManagedByImport"
            />
            <v-text-field
                filled
                :label="t('address.country')"
                v-model="data.address.country"
                :readonly="isManagedByImport"
            />

          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('user-roles.group-title') }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="data.role" :disabled="data.id === 1">
              <v-radio v-if="isAdmin()" :label="$t('users.data.roles.admin')" value="admin"/>
              <v-radio :label="$t('users.data.roles.coordinator')" value="coordinator"/>
              <v-radio :label="$t('users.data.roles.employee')" value="employee"/>
              <v-radio :label="$t('users.data.roles.customer')" value="customer"/>
              <v-radio :label="$t('users.data.roles.none')" value="none"/>
            </v-radio-group>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('login-data.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
                filled
                :label="t('login-data.username')"
                :disabled="true"
                v-model="data.username"
            />
            <v-text-field
                filled
                :label="t('login-data.password')"
                v-model="data.password"
                type="password"
            />
            <div v-if="data.login_enabled">
              <div>{{ t('login-data.login-enabled') }}</div>
              <v-btn
                  outlined
                  color="primary"
                  @click="disableLogin"
                  :loading="loginLoading"
              >
                {{ t('login-data.do-disable') }}
              </v-btn>
            </div>
            <div v-if="!data.login_enabled">
              <div>{{ t('login-data.login-disabled') }}</div>
              <v-btn
                  outlined
                  color="primary"
                  v-if="!data.login_enabled"
                  @click="enableLogin"
                  :loading="loginLoading"
              >
                {{ t('login-data.do-enable') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="loginUser && loginUser.role === 'admin'" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('device-info.group-title') }}</v-card-title>
          <v-card-text v-if="data.device_info && data.device_info.length > 0" class="device-info">
            <span>{{ t('device-info.device') }}</span><br>
            <p>{{ data.device_info[0].device_id }}</p>
            <span>{{ t('device-info.app_version') + data.device_info[0].app_version }}</span><br><br>
            <span>{{ t('device-info.os') }}</span><br>
            <p>{{ data.device_info[0].os }}</p>
            <span>{{ t('device-info.os_version') + data.device_info[0].os_version }}</span><br><br>
            <span>{{ t('device-info.push') }}</span><br>
            <p>
              <v-icon v-if="data.device_info[0].push_enabled" color="success">mdi-cellphone-check</v-icon>
              <v-icon v-else color="warning">mdi-cellphone-remove</v-icon>
              {{ data.device_info[0].push_enabled ? t('device-info.push_enabled') : t('device-info.push_disabled') }}<br>
              <v-text-field
                class="mt-4"
                filled
                :label="t('device-info.push_token')"
                v-model="data.device_info[0].push_id"
                readonly
                hide-details="auto"
              ></v-text-field>
            </p>
          </v-card-text>
        </v-card>

        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('admin.group-title') }}</v-card-title>
          <v-card-text>
            <content-filter-employee
                :label="t('admin.manager')"
                v-model="data.manager"/>
          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('access-rights.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <div class="sp-pad-2">
                <div class="md-body-2">{{ t('access-rights.customers.title') }}</div>
                <span class="md-caption">{{ t('access-rights.customers.hint') }}</span>
                <div>
                  <md-input-container>
                    <label>{{ t('access-rights.customers.search') }}</label>
                    <md-input type="text" v-model="searchCustomers"></md-input>
                    <md-icon>search</md-icon>
                  </md-input-container>
                </div>

                <div class="search-items" v-for="(item, index) in customers" :key="item.id"
                     @click="selectItem('customers', item)" v-if="searchCustomers !== ''"> {{ item.display_name }}
                </div>
                <div v-if="data.assigned.customers !== 0" v-for="(item, index) in data.assigned.customers"
                     :key="item.id" class="search-items item-selected-inline search-multi">
                  <span>{{ item.name != null ? item.name : item.lastname + ', ' + item.firstname }}</span>
                  <div class="sp-float-right">
                    <md-icon class="sp-text-white" @click.native="removeItem('customers', index)">remove_circle
                    </md-icon>
                  </div>
                </div>
              </div>

              <md-divider v-if="moduleEnabled('quality_report')"></md-divider>

              <div v-if="moduleEnabled('quality_report')" class="sp-pad-2">
                <div class="md-body-2">{{ t('access-rights.checklists.title') }}</div>
                <span class="md-caption">{{ t('access-rights.checklists.hint') }}</span>
                <div>
                  <md-input-container>
                    <label>{{ t('access-rights.checklists.search') }}</label>
                    <md-input type="text" v-model="searchChecklists"></md-input>
                    <md-icon>search</md-icon>
                  </md-input-container>
                </div>
                <div class="search-items"
                     v-for="(item, index) in checklists" :key="item.id"
                     @click="selectItem('checklists', item)" v-if="searchChecklists !== ''"> {{ item.name }}
                </div>
                <div v-if="data.assigned.checklists !== 0" v-for="(item, index) in data.assigned.checklists"
                     :key="item.id" class="search-items item-selected-inline search-multi">
                  <span>{{ item.name }}</span>
                  <div class="sp-float-right">
                    <md-icon class="sp-text-white" @click.native="removeItem('checklists', index)">remove_circle
                    </md-icon>
                  </div>
                </div>
              </div>

              <md-divider v-if="moduleEnabled('quality_report')"></md-divider>
            </div>
            <div class="sp-pad-2">
              <permission-group-selector
                  v-model="data.assigned.permission_groups"
                  :required="false"
                  mode="dropdown"/>
            </div>
            <div class="sp-pad-2">
              <CustomerProjectSelector @item-selected="onCustomerProjectSelected"/>
              <div v-for="(item, index) in data.customer_projects_ids">
                <v-row>
                  <v-col>{{ customer_projects[item].customer.name }}</v-col>
                  <v-col>{{ customer_projects[item].name }}</v-col>
                  <v-col>
                    <v-btn @click="data.customer_projects_ids.splice(index, 1)">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </div>
            </div>
          </v-card-text>
        </v-card>

      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import axios from 'axios'
import store from 'store'
import {hasModule} from '@/services/LicenseService'
import _ from 'lodash'
import 'cropperjs/dist/cropper.css'
import PictureUpload from "@/components/inputs/PictureUpload"
import BasicEditContainer from "@/components/inputs/BasicEditContainer"
import CustomerProjectSelector from "@/components/CustomerProjectSelector"
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker";
import SalutationSelect from "@/components/SalutationSelect.vue";
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue";
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee.vue";

export default {
  name: 'UsersEdit',
  components: {
    ContentFilterEmployee,
    PermissionGroupSelector,
    CustomerProjectSelector,
    BasicEditContainer,
    PictureUpload,
    AppFilterDatePicker,
    SalutationSelect
  },
  data() {
    return {
      modules: [],
      data: {
        assigned: {
          groups: [],
          customers: [],
          checklists: [],
          permission_groups: []
        },
        notifications: {
          groups: [],
          customers: [],
          checklists: []
        },
        picture: null,
        role: null,
        customer_projects_ids: [],
        active: true,
        name: '',
        firstname: '',
        email: '',
        phone_number: '',
        mobile_phone_number: '',
        emergency_phone_number: '',
        workplace: '',
        profession: '',
        phonebook_hidden: 0,
        username: '',
        password: '',
        login_enabled: true,
        profileImageChanged: false,
        idp_managed: false,
        address: {
          address_line_1: null,
          address_line_2: null,
          zip_code: null,
          place: null,
          country: null
        },
        salutation: '',
        iban: '',
        bank: '',
        birth_date: '',
        notes: '',
        begin_date: '',
        end_date: '',
        manager: null,
      },
      customer_projects: {},
      dataOriginal: null,
      searchGroups: '',
      searchCustomers: '',
      searchChecklists: '',
      searchGroupsNotifications: '',
      searchCustomersNotifications: '',
      searchChecklistsNotifications: '',
      groups: null,
      permissionGroups: null,
      customers: null,
      checklists: null,
      groupsNotifications: null,
      customersNotifications: null,
      checklistsNotifications: null,
      dataLoaded: false,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      uploadTarget: 'users',
      isManagedByImport: false,
      refreshKey: 0,
      loginLoading: false,
      loginUser: null
    }
  },
  watch: {
    searchGroups: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val))
    },
    searchCustomers: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val))
    },
    searchChecklists: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val))
    },
    searchGroupsNotifications: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val), true)
    },
    searchCustomersNotifications: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val), true)
    },
    searchChecklistsNotifications: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val), true)
    },
  },
  mounted() {
    this.getPermissionGroups()
    this.getUser()
  },
  created() {
    this.loginUser = store.get('user')
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
    title() {
      let title = this.data.firstname + ' ' + this.data.name + ' ';
      title = title + (this.isManagedByImport ? '(importiert)' : 'bearbeiten');
      return title;
    },
    emailRules() {
      if (this.isManagedByImport) {
        return []
      }
      if (this.data.email == "") {
        return []
      }
      return [
        v => /.+@.+/.test(v) || this.$t('validation.valid.email'),
      ]
    },
  },
  methods: {
    t: function (key) {
      return this.$t('users.detail.' + key);
    },
    moduleEnabled(string) {
      return hasModule(string)
    },
    onCustomerProjectSelected(id, data) {
      this.data.customer_projects_ids.push(id);
      this.customer_projects[id] = data;
    },
    getPermissionGroups() {
      HTTP.get('permission-groups').then(function (response) {
        this.permissionGroups = response.data.data
      }.bind(this)).catch(function (error) {
        this.error = error
      }.bind(this))
    },
    extractPermissionGroupsId() {
      var permGroupsIds = []
      var permGroups = this.data.assigned.permission_groups.length
      for (var i = 0; i < permGroups; i++) {
        permGroupsIds.push(this.data.assigned.permission_groups[i].id)
      }
      this.data.assigned.permission_groups = permGroupsIds
    },
    async getUser() {
      let response = await HTTP.get('users/' + this.itemId)
      this.data = response.data.data
      response.data.customer_projects.forEach(function (val) {
        this.customer_projects[val.id] = val;
      }.bind(this));

      this.extractPermissionGroupsId()

      if (this.data.profile_image) {
        let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''

        this.data = {
          ...this.data,
          picture: BASE_URL + '/api/users/image/' + this.data.id + '/' + this.data.profile_image
        };
      }

      this.isManagedByImport = this.data.imported
      this.dataOriginal = JSON.parse(JSON.stringify(this.data));

      this.dataLoaded = true
    },
    isAdmin() {
      return this.loginUser && this.loginUser.role === "admin"
    },
    getSearchResultGroups(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('groups?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.groups = response.data
        } else {
          this.groupsNotifications = response.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultCustomers(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.customers = response.data.data
        } else {
          this.customersNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultChecklists(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('checklists?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.checklists = response.data.data
        } else {
          this.checklistsNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    selectItem(type, item, notifications = false) {
      if (!notifications) {
        this.data.assigned[type].push(item)
        this.searchGroups = ''
        this.searchCustomers = ''
        this.searchChecklists = ''
        this.checkDuplicates(type)
      } else {
        this.data.notifications[type].push(item)
        this.searchGroupsNotifications = ''
        this.searchCustomersNotifications = ''
        this.searchChecklistsNotifications = ''
        this.checkDuplicates(type, true)
      }
    },
    removeItem(type, index, notifications = false) {
      if (notifications) {
        this.data.notifications[type].splice(index, 1)
      } else {
        this.data.assigned[type].splice(index, 1)
      }
    },
    checkDuplicates(type, notifications = false) {
      if (notifications) {
        this.data.notifications[type] = _.uniqBy(this.data.notifications[type], 'id')
      } else {
        this.data.assigned[type] = _.uniqBy(this.data.assigned[type], 'id')
      }
    },
    inArray(needle, haystack) {
      var length = haystack.length
      for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) {
          return true
        }
      }
      return false
    },
    close() {
      this.$router.go(-1);
    },
    cancel() {
      this.close();
    },
    saveItem() {
      this.data.profileImageChanged = this.data.picture !== this.dataOriginal.picture
      this.data.manager_id = this.data.manager?.id
      return HTTP.patch('users/' + this.itemId, this.data);
    },
    onSaved() {
      this.close();
    },
    refresh() {
      this.refreshKey++
    },
    async disableLogin() {
      try {
        this.loginLoading = true
        let response = await HTTP.post('users/' + this.itemId + '/login-disable')
        this.data.login_enabled = response.data.login_enabled
      } finally {
        this.loginLoading = false
      }
    },
    async enableLogin() {
      try {
        this.loginLoading = true
        let response = await HTTP.post('users/' + this.itemId + '/login-enable')
        this.data.login_enabled = response.data.login_enabled
      } finally {
        this.loginLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.device-info {

  p {
    font-size: 16px;
    font-weight: 500;
  }

  i {
    position: relative;
    left: -4px;
  }
}  
</style>
