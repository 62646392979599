<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ $t('expenses.list.title') }}</toolbar>
      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
          <app-filter-apply-refresh-button
              :has-changes="filterStore.hasChanges"
              :is-loading="loading"
              @click="applyFilter"
          />

          <v-slide-group show-arrows>
            <v-slide-item>
              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                      :filterLabel="t('from')"
                      v-model="filterStore.filter.dateStart"
                      id="dateStart"
                      clearable
                  ></app-filter-date-picker>
                </div>
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item">
                <app-filter-date-picker
                    :filterLabel="t('to')"
                    v-model="filterStore.filter.dateEnd"
                    id="dateEnd"
                    clearable
                ></app-filter-date-picker>
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item filter-search-list">
                <content-filter-employee
                    v-model="filterStore.filter.selectedUser"
                />
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>

          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                @click="dialogs.createReportVisible = true"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('tasks.list.create') }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>
        <v-row v-else>
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-0"
              :sort-by="initialSortBy"
              :sort-desc="initialSortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-options': [25, 50, 100, 200],
                pageText: '{0}-{1} von {2}',
                'items-per-page-text': t('table.items-per-page')
              }"
          >
            <template v-slot:[`item.date`]="{ item }">
              <span class="ellipsis">{{ formatDate(item.date) }}</span>
            </template>

            <template v-slot:[`item.firstname`]="{ item }">
              {{ item.user.firstname }}
            </template>

            <template v-slot:[`item.lastname`]="{ item }">
              {{ item.user.lastname }}
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              {{ item.total_amount }} {{ item.currency }}
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ path: '/expense-reports/' + item.id }" class="edit-row-table">
                <v-icon small class="edit-row-button">view</v-icon>
              </router-link>

              <v-btn  class="edit-row-table" @click="doExport(item)">
                <v-icon small class="edit-row-button">download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </div>

    <v-dialog
        v-model="dialogs.createReportVisible"
        width="828"
        persistent
    >
      <expense-report-create
          @cancel="dialogs.createReportVisible = false"
          @save="createReport"
      />
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import edit from '@/views/AbsencesEdit.vue'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import ExpenseStatusFilter from '@/components/filter-components/ExpenseStatusFilter'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import {useExpenseFilterStore} from '@/stores/ExpenseFilterStore'
import StatusIcons from '@/components/layouts/StatusIcons'
import AppFilterApplyRefreshButton from '@/components/vuetify/AppFilterApplyRefreshButton'
import TimeManagementBalanceEditVacationBooking from "@/views/TimeManagementBalanceEditVacationBooking.vue";
import ExpenseReportCreate from "@/views/ExpenseReportCreate.vue";

export default {
  name: 'ExpenseIndex',
  components: {
    ExpenseReportCreate,
    TimeManagementBalanceEditVacationBooking,
    AppFilterApplyRefreshButton,
    StatusIcons,
    AppFilterApplyButton,
    ExpenseStatusFilter,
    ContentFilterEmployee,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField,
    edit
  },
  setup() {
    const filterStore = useExpenseFilterStore()
    return {filterStore}
  },
  data() {
    return {
      items: [],
      loading: false,
      options: {
        itemsPerPage: 10
      },
      initialSortBy: '',
      initialSortDesc: false,
      dataLoaded: false,
      dialogs: {
        createReportVisible: false
      }
    }
  },
  created() {
    this.applyFilter();
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.date'),
          align: 'start',
          sortable: false,
          value: 'date',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.firstname'),
          align: 'start',
          sortable: false,
          value: 'firstname',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.lastname'),
          align: 'start',
          sortable: false,
          value: 'lastname',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.amount'),
          align: 'end',
          sortable: false,
          value: 'amount',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.details'),
          align: 'center',
          sortable: false,
          value: 'details'
        }
      ];
    },
  },
  methods: {
    t: function (key) {
      return this.$t('expense-reports.list.' + key);
    },
    buildFilter() {
      let filter = this.filterStore.filter;
      return {
        from: filter.dateStart,
        to: filter.dateEnd,
        userid: filter.selectedUser?.id,
        status: filter.status,
      }
    },
    async loadData() {

      this.loading = true;

      let filter = this.buildFilter()
      try {
        let response = await HTTP.get('expense-reports', {params: filter})
        this.items = response.data.data
      } finally {
        this.loading = false
      }
    },
    async reload() {
      this.items = []
      await this.loadData()
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      await this.reload()
    },
    async createReport() {
      await HTTP.post('expense-reports/')
      await this.loadData()
      this.dialogs.createReportVisible = false
    },
    async doExport(item) {
      this.processing = item.id
      const config = {
        headers: {'content-type': 'application/json'},
        responseType: 'arraybuffer'
      }

      let response = await HTTP.get('expense-reports/' + item.id + '/pdf/', config)
      let headers = response.headers
      let blob = new Blob([response.data], {type: headers['content-type']})
      let link = document.createElement('a')
      link.style = 'display: none'
      link.href = window.URL.createObjectURL(blob)
      link.download = 'ExpenseReport_' + item.id + '.pdf'
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(blob)
      }, 100)
      this.isDownloadingExcel = false
      this.processing = 0;
    },
  }
}
</script>

<style scoped lang="scss">

</style>
