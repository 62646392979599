import ExpensesIndex from "../../views/ExpenseIndex";
import ExpenseEdit from "@/views/ExpenseEdit";
import ExpenseSettings from "@/views/ExpenseSettings.vue";
import ExpenseReportIndex from "@/views/ExpenseReportIndex.vue";


const expensesRoutes = [
  {
    path: '/expenses',
    name: 'Expenses',
    component: ExpensesIndex,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/expenses/:id?',
    name: 'Expense',
    component: ExpenseEdit,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/expense-reports',
    name: 'ExpenseReports',
    component: ExpenseReportIndex,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/expense',
    name: 'ExpenseSettings',
    component: ExpenseSettings,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
]

export default expensesRoutes
