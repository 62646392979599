<template>
  <div>
    <v-card>
      <v-card-title>
        {{ t('title') }}
      </v-card-title>
      <v-card-text
          class="d-flex"
      >
        <v-container>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()"
               text
        >
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
        <v-btn @click="save()"
               color="primary"
               elevation="0"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ExpenseReportCreate",
  data() {
    return {
      data: {
        title: '',
        details: '',
        date: new Date().toISOString().substr(0, 10),
        hours: 0,
      }

    }
  },
  methods: {
    t: function (key) {
      return this.$t('expense-report-create.' + key);
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      let data = this.data;
      data.amount_minutes = data.hours * 60;
      this.$emit('save', data);
    },
  }
}
</script>

<style scoped>

</style>
